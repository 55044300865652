import './style.css'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import logo from '../../images/logo.png'

const Navbar = () => {
  const [expanded, setExpanded] = useState(false)


  return (
    <nav>
      <div className="nav container">
        <a href="/" className="brand-name">
          <img src={logo} alt="logo" />
          {/* 4Runner Global Services */}
        </a>
        <button
          className="hamburger"
          onClick={() => {
            setExpanded(!expanded);
          }}
        >
          <i className="fa-solid fa-bars"></i>
        </button>
        <div
          className={expanded ? "nav-menu expanded" : "nav-menu"}
          onClick={() => {
            setExpanded(!expanded);
          }}
        >
          <ul>
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to="/about">About</Link>
            </li>
            <li>
              <Link to="/4runner-oil-and-gas">Certificates</Link>
            </li>
            <li>
              <Link to="/gallery">Gallery</Link>
            </li>
            <li>
              <Link to="/contact">Contact</Link>
            </li>
          </ul>
        </div>
        {/* <button className="nav-btn">request quote</button> */}
        <Link to="/contact">
          <button className="nav-btn">request quote</button>
        </Link>
      </div>
    </nav>
  );
}

export default Navbar
