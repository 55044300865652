import React from "react";
import "./style.css";
import { motion } from "framer-motion";

const Services = ({ services }) => {
  return (
    <motion.section
      className="services"
      initial={{ opacity: 0, y: 100 }}
      whileInView={{ opacity: 1, y: 0 }}
      transition={{ ease: "easeInOut", duration: 1 }}
    >
      <motion.h1
        initial={{ opacity: 0, y: 100 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{ ease: "easeInOut", duration: 1 }}
      >
        Our Competence
      </motion.h1>
      <div className="cards">
        {services.map((service, index) =>
          index % 2 === 0 ? (
            <motion.div
              className="card card-odd"
              key={index}
              initial={{ opacity: 0, y: 100 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ ease: "easeInOut", duration: 1 }}
            >
              <div className="card-info container">
                <img src={service.image} alt={service.title} />
                <div className="card-details">
                  <h3>{service.title}</h3>
                  <p>{service.details}</p>
                </div>
              </div>
            </motion.div>
          ) : (
            <motion.div
              className="card card-even"
              key={index.toString()}
              initial={{ opacity: 0, y: 100 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ ease: "easeInOut", duration: 1 }}
            >
              <div className="card-info container">
                <div className="card-details">
                  <h3>{service.title}</h3>
                  <p>{service.details}</p>
                </div>
                <img src={service.image} alt={service.title} />
              </div>
            </motion.div>
          )
        )}
      </div>
    </motion.section>
  );
};

export default Services;
