import React from "react";
import "./style.css";

import gallery1 from "../../images/gallery1.jpg";
import gallery2 from "../../images/gallery2.jpg";
import gallery3 from "../../images/gallery3.jpg";
import gallery4 from "../../images/gallery4.jpg";
import gallery5 from "../../images/gallery5.jpg";
import gallery6 from "../../images/gallery6.jpg";
import gallery7 from "../../images/gallery7.jpg";
import gallery8 from "../../images/gallery8.jpg";
import gallery9 from "../../images/gallery9.jpg";
import gallery10 from "../../images/gallery10.jpg";
import gallery11 from "../../images/gallery11.jpg";
import gallery12 from "../../images/gallery12.jpg";
import gallery13 from "../../images/gallery13.jpg";
import gallery14 from "../../images/gallery14.jpg";
import gallery15 from "../../images/gallery15.jpeg";
import gallery16 from "../../images/gallery16.jpeg";
import gallery17 from "../../images/gallery17.jpeg";
import gallery18 from "../../images/gallery18.jpeg";
import gallery19 from "../../images/gallery19.jpg";
import gallery20 from "../../images/gallery20.jpeg";
import gallery21 from "../../images/gallery21.jpeg";
import gallery22 from "../../images/gallery22.jpeg";
import gallery23 from "../../images/gallery23.jpeg";
import gallery24 from "../../images/gallery24.jpeg";


import Footer from "../../components/Footer/Footer";

const Gallery = () => {
  const photos = [
    gallery1,
    gallery2,
    gallery3,
    gallery4,
    gallery5,
    gallery6,
    gallery7,
    gallery14,
    gallery15,
    gallery16,
    gallery17,
    gallery18,
    gallery19,
    gallery8,
    gallery9,
    gallery10,
    gallery11,
    gallery12,
    gallery13,
    gallery23,
    gallery24,
    gallery20,
    gallery21,
    gallery22,
  ];

  return (
    <>
      <section className="albums">
        <h1>Gallery</h1>
        <div className="album container">
          {photos.map((photo, i) => (
            <div className="photo" key={i}>
              <img src={photo} alt="" />
            </div>
          ))}
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Gallery;
