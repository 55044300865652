import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom'
import "./style.css";
import log from "../../images/logistics.jpg";

const Hero = () => {
  const words = ["reliable", "dependable", "innovative", "consistent"];
  const [index, setIndex] = useState(0)

  useEffect(() => {
    const tick = () => setIndex((i) => i + 1);

    const id = setInterval(tick, 2000);
    return () => clearInterval(id);
  })

  return (
    <div className="hero container">
      <div className="hero-left">
        <h1>
          Efficient and{" "}
          <span className={index % 2 === 0 ? "text" : "word"} style={{}}>
            {" "}
            {words[index % words.length]}{" "}
          </span>{" "}
          Logistics Services for Your Business Needs
        </h1>
        <p>
          We are an indigenous firm offering a wide range of services and
          solution which includes but not limited to Importation, Exportation,
          Freight forwarding (Air, Sea & Land) Contracting, Supply chain
          management distribution, warehousing.
        </p>
        <Link to="/contact">
          <button className="hero-btn">book shipment</button>
        </Link>
      </div>
      <div className="hero-right">
        <img src={log} alt="hero_logistics" />
      </div>
    </div>
  );
};

export default Hero;
