import React from "react";
import { motion } from "framer-motion";
// import Icon from "../../images/icon.png";
import dependability from "../../images/dependability.png";
import reliability from "../../images/realiability.png";
import innovation from "../../images/innovative.png";
import positivity from "../../images/positivity.png";
import consistency from "../../images/consistency.png";
import shipment from "../../images/shipment1.png";
import "./style.css";

const About = () => {
  return (

    <section className="about-us">
      <motion.div
        className="abt container"
        initial={{ opacity: 0, y: 100 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{ ease: "easeInOut", duration: 1 }}
      >
        <motion.div
          className="left"
          initial={{ opacity: 0, x: -300 }}
          whileInView={{ opacity: 1, x: 0 }}
          transition={{ ease: "easeInOut", duration: 1 }}
        >
          <img src={shipment} alt="about-us" />
        </motion.div>
        <motion.div
          className="right"
          initial={{ opacity: 0, x: 300 }}
          whileInView={{ opacity: 1, x: 0 }}
          transition={{ ease: "easeInOut", duration: 1 }}
        >
          <h2>
            We've been proudly delivering cargo since <span>2005</span>
          </h2>
          <p>
            4runner global service limited was incorporated in 2005 became a
            limited liability company in 2018 due to strategic business
            expansion with the sole aim of providing excellent logistics and
            supply chain services. We are an indigenous firm offering a wide
            range of services and solution which includes but not limited to
            Importation, Exportation, Freight forwarding (Air, Sea & Land)
            Contracting, Supply chain management distribution, warehousing.
          </p>
        </motion.div>
      </motion.div>
      <motion.div
        className="values container"
        initial={{ opacity: 0, y: 100 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{ ease: "easeInOut", duration: 1 }}
      >
        <motion.h2
          initial={{ opacity: 0, y: 100 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ ease: "easeInOut", duration: 1 }}
        >
          Our Core Values
        </motion.h2>
        <motion.div
          className="qualities"
          initial={{ opacity: 0, y: 100 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ ease: "easeInOut", duration: 1 }}
        >
          <div className="icon-box">
            <img src={dependability} alt="" />
            <p>Dependability</p>
          </div>
          <div className="icon-box">
            <img src={reliability} alt="" />
            <p>Reliability</p>
          </div>
          <div className="icon-box">
            <img src={innovation} alt="" />
            <p>Innovation</p>
          </div>
          <div className="icon-box">
            <img src={positivity} alt="" />
            <p>Positivity</p>
          </div>
          <div className="icon-box">
            <img src={consistency} alt="" />
            <p>Consistency</p>
          </div>
        </motion.div>
      </motion.div>
    </section>
  );
};

export default About;
