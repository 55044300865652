import React from 'react'
import './style.css'
import gla from "../../images/GLA2.jpg";
import mgln from "../../images/mgln.jpg";
import naga from "../../images/naga.jpg";

const Footer = () => {
  return (
    <>
      <footer>
        <div className="footer container">
          <div className="icons">
            <img src={gla} alt="gla" />
            <img src={mgln} alt="mgln" />
            <img src={naga} alt="naga" />
          </div>
          <div className="info">
            <h3>4Runner Global Services Limited</h3>
            <p>
              was incorporated in 2005 became a limited liability company in
              2018 due to strategic business expansion with the sole aim of
              providing excellent logistics and supply chain services.
            </p>
          </div>
          <div className="contact-info">
            {/* <p>10 Adegbola Street by Ikeja Local Govt Area Lagos</p>
            <p>3201 E. Pioneer Pkwy #7 Arlington Texas 76010</p>
            <p>info@4runnerglobal.org</p>
            <p>+234 909 009 6507</p>
            <p>+234 909 009 6505</p>
            <p>+14696846899</p> */}
            <div className="socials">
              <a
                href="https://wa.me/2349090096507"
                target="_blank"
                rel="noreferrer"
              >
                <i className="fa-brands fa-whatsapp"></i>
              </a>
              <a
                href="https://web.facebook.com/people/4Runner-Global-Services-Limited/100066861667424/?_rdc=1&_rdr"
                target="_blank"
                rel="noreferrer"
              >
                <i className="fa-brands fa-facebook"></i>
              </a>
              <a
                href="https://twitter.com/4runnerG"
                target="_blank"
                rel="noreferrer"
              >
                <i className="fa-brands fa-twitter"></i>
              </a>
              <a
                href="https://www.instagram.com/4runnerglobalservice_/"
                target="_blank"
                rel="noreferrer"
              >
                <i className="fa-brands fa-instagram"></i>
              </a>
              {/* <i className="fa-brands fa-google-plus-g"></i> */}
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}

export default Footer
