import React, { useState, useEffect } from "react";

const Count = ({ num }) => {
  const [count, setCount] = useState("0");

  useEffect(() => {
      let start = 0;
      const end = parseInt(num.substring(0, 3))

      if (start === end) return;

      const duration = (parseInt("2") / end) * 1000

      let timer = setInterval(() => {
          start += 1

          setCount(String(start) + num.substring(3))
          if (start === end) {
              clearInterval(timer)
    
          };
      }, duration)
  }, [num]);
    return <>{ count }</>;
};

export default Count;
