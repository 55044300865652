import React from 'react'
import './style.css'
import {motion} from 'framer-motion'
import NRC from "../../images/NRC.jpg";
import Benkline from "../../images/Benkline-Nigeria-Limited.jpg";
import Divcon from "../../images/divcon.jpg";
import Avolad from "../../images/AVOLAD.jpg";
import Army from "../../images/ARMY-150x72.jpg";
import arcade from "../../images/arcade-150x72.jpg";
import pmr from "../../images/pmr-150x72.jpg";
import uniben from "../../images/University-of-Benin-Teaching-hospital.jpg";
import servic from "../../images/servic-pdc.jpg";
import coschairs from "../../images/log-118x75.png";
import neimeth from "../../images/neimeth.jpg";
import GE from "../../images/GE-Healthcare.jpg";

const Clients = () => {
  return (
    <>
      <motion.section
        className="clients"
        initial={{ opacity: 0, y: 100 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{ ease: "easeInOut", duration: 1 }}
      >
        <div className="container">
          <motion.h1
            initial={{ opacity: 0, y: 100 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ ease: "easeInOut", duration: 1 }}
          >
            Our Clients
          </motion.h1>
          <div className="partners">
            <div className="img-box">
              <img src={NRC} alt="nrc" />
            </div>
            <div className="img-box">
              <img src={Benkline} alt="benkline" />
            </div>
            <div className="img-box">
              <img src={Divcon} alt="divcon" />
            </div>
            <div className="img-box">
              <img src={Avolad} alt="avolad" />
            </div>
            <div className="img-box">
              <img src={Army} alt="army" />
            </div>
            <div className="img-box">
              <img src={arcade} alt="arcade" />
            </div>
            <div className="img-box">
              <img src={pmr} alt="pmr" />
            </div>
            <div className="img-box">
              uniben
              <img src={uniben} alt="" />
            </div>
            <div className="img-box">
              <img src={servic} alt="servic" />
            </div>
            <div className="img-box">
              <img src={coschairs} alt="coschairs" />
            </div>
            <div className="img-box">
              <img src={neimeth} alt="neimeth" />
            </div>
            <div className="img-box">
              <img src={GE} alt="ge" />
            </div>
          </div>
        </div>
      </motion.section>
    </>
  );
}

export default Clients
