import React from "react";
import "./style.css";
import { motion } from 'framer-motion'
import about from "../../images/gallery8.jpg";
import dependability from "../../images/dependability.png";
import reliability from "../../images/realiability.png";
import innovation from "../../images/innovative.png";
import positivity from "../../images/positivity.png";
import consistency from "../../images/consistency.png";
import Footer from "../../components/Footer/Footer";
import Count from "../../components/Count";

const About = () => {


  return (
    <>
      <section>
        <div className="about-hero">
          <div className="container">
            <img src={about} alt="about" />
            <div className="about-info">
              <motion.h1
                initial={{ opacity: 0, y: 200 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ ease: "easeInOut", duration: 1 }}
              >
                4Runner Global Services Limited
              </motion.h1>
              <motion.p
                initial={{ opacity: 0, y: 200 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ ease: "easeInOut", duration: 1 }}
              >
                was incorporated in 2005 became a limited liability company in
                2018 due to strategic business expansion with the sole aim of
                providing excellent logistics and supply chain services. We are
                an indigenous firm offering a wide range of services and
                solution which includes but not limited to Importation,
                Exportation, Freight forwarding (Air, Sea & Land) Contracting,
                Supply chain management distribution, warehousing.
              </motion.p>
              <motion.p
                initial={{ opacity: 0, y: 100 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ ease: "easeInOut", duration: 1 }}
              >
                Over the years we have satisfactorily carried out numerous
                logistics services in these areas: Oil and Gas, Aviation,
                Telecommunication, Manufacturing, Agricultural, Agencies and
                Government sector. We have the capacity and capability to
                deliver end to end solutions which surpasses our customers'
                expectation. We pride ourselves with proven accomplishment of
                delivering top notch services through our experienced logistics
                expert for excellent customer service satisfaction.
              </motion.p>
            </div>
          </div>
        </div>

        <div className="core-values">
          <div className="container core">
            <div className="core-left">
              <div className="vision">
                <h3>Vision</h3>
                <p>
                  To be the top-notch Global Runner for our clients need from
                  minor to major projects
                </p>
              </div>
              <div className="mission">
                <h3>Mission</h3>
                <p>
                  To continually provide unmatched customer satisfaction by
                  adopting the balanced scorecard model taking into
                  consideration our internal processes, learning and growth for
                  employee, customer/client perspective which translates to
                  overall performance of the organization.
                </p>
              </div>
              <div className="team">
                <h3>Team</h3>
                <p>
                  Our team consist of highly trained and sought-after
                  individuals whose expertise cuts across Procurement, Quality
                  management, HSE, Human Resources and administration, Supply
                  chain management, Project management & Information
                  technology/Security all put together and totaling over 40years
                  industry experience.
                </p>
              </div>
            </div>
            <div className="core-right">
              <h2>Our Core values</h2>
              <div className="about-icons">
                <div className="about-icon">
                  <img src={dependability} alt="dependable" />
                  <p>dependability</p>
                </div>
                <div className="about-icon">
                  <img src={reliability} alt="reliable" />
                  <p>relationship</p>
                </div>
                <div className="about-icon">
                  <img src={innovation} alt="innovation" />
                  <p>innovation</p>
                </div>
                <div className="about-icon">
                  <img src={positivity} alt="positivity" />
                  <p>positivity</p>
                </div>
                <div className="about-icon">
                  <img src={consistency} alt="consistency" />
                  <p>consistency</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="statistics">
          <div className="container stats">
            <div className="stat">
              <span className="num">
                <Count num={"10596"} />
              </span>
              <p>parcels</p>
            </div>
            <div className="stat">
              <span className="num">
                <Count num={"196"} />
              </span>
              <p>cities</p>
            </div>
            <div className="stat">
              <span className="num">
                <Count num={"576"} />
              </span>
              <p>clients</p>
            </div>
            <div className="stat">
              <span className="num">
                <Count num={"10243"} />
              </span>
              <p>Repeated customers</p>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default About;
