import React, { useEffect, useState } from 'react'
import './style.css'
import {motion} from 'framer-motion'
import gallery1 from "../../images/gallery1.jpg";
import gallery2 from "../../images/gallery2.jpg";
import gallery3 from "../../images/gallery3.jpg";
import gallery4 from "../../images/gallery4.jpg";
import gallery5 from "../../images/gallery5.jpg";
import gallery6 from "../../images/gallery6.jpg";
import gallery7 from "../../images/gallery7.jpg";
import gallery8 from "../../images/gallery8.jpg";
import gallery9 from "../../images/gallery9.jpg";



const Gallery = () => {
  const [current, setCurrent] = useState(0);
  const slides = [
    { image: [gallery1, gallery2, gallery3] },
    { image: [gallery4, gallery5, gallery6] },
    { image: [gallery7, gallery8, gallery9] },
  ];
  const length = slides.length;

  useEffect(() => {
    const slider = setInterval(
      () => setCurrent(current === length - 1 ? 0 : current + 1),
      5000
    );

    return () => clearInterval(slider)
  })

  const nextSlide = () => {
    setCurrent(current === length - 1 ? 0 : current + 1);
  };

  const prevSlide = () => {
    setCurrent(current === 0 ? length - 1 : current - 1);
  };

  if (!Array.isArray(slides) || slides.length <= 0) {
    return null;
  }

  return (
    <>
      <motion.section
        className="gallery"
        initial={{ opacity: 0, y: 200 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{ ease: "easeInOut", duration: 1 }}
      >
        <div className="container">
          <motion.h1
            initial={{ opacity: 0, y: 100 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ ease: "easeInOut", duration: 1 }}
          >
            Gallery
          </motion.h1>
          <motion.div
            className="image-gallery"
            initial={{ opacity: 0, y: 100 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ ease: "easeInOut", duration: 1 }}
          >
            <button className="left-arrow" onClick={prevSlide}>
              <i className="fa-solid fa-angle-left"></i>
            </button>
            <button className="right-arrow" onClick={nextSlide}>
              <i className="fa-solid fa-angle-right"></i>
            </button>
            {slides.map((slide, index) => (
              <div
                className={index === current ? "slide active" : "slide"}
                key={index}
              >
                {index === current &&
                  slide.image.map((image, i) => (
                    <img src={image} alt="gallery" className="image" key={i} />
                  ))}
              </div>
            ))}
          </motion.div>
          <button className="gallery-btn">view more</button>
        </div>
      </motion.section>
    </>
  );
}

export default Gallery
