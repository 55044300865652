import React from "react";
import "./style.css";
import cert1 from "../../images/4RUNNER-FREIGHT_CLEARING-AND-FORWARDING-DPR-PERMIT-scaled.jpg";
import cert2 from "../../images/4RUNNER-DPR-PERMIT-_-VALVES-NOZZLES-AND-FLANGES-BOLTS-AND-NUTS-scaled.jpg";
import cert3 from "../../images/4RUNNER-FREIGHT_CLEARING-AND-FORWARDING-DPR-PERMIT.jpg";
import Footer from "../../components/Footer/Footer";

const Runner = () => {
  return (
    <>
      <div className="certificate">
        <h1>Certificates</h1>
        <div className="certs container">
          <div className="cert">
            <h3>CLEARING AND FORWARDING DPR PERMIT</h3>
            <img src={cert1} alt="cert1" />
          </div>
          <div className="cert">
            <h3>VALVES NOZZLES AND FLANGES BOLTS AND NUTS</h3>
            <img src={cert2} alt="cert2" />
          </div>
          <div className="cert">
            <h3>PETROLEUM PRODUCTS HAULAGE AND GOODS</h3>
            <img src={cert3} alt="cert3" />
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Runner;
