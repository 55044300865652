import React from "react";
import Hero from "../../components/Hero/Hero";
import Services from "../../components/Services/Services";
import About from "../../components/About/About";
import Gallery from '../../components/Gallery/Gallery'
import Clients from '../../components/Clients/Clients'
import Footer from '../../components/Footer/Footer'
import "./style.css";
// import serviceData from '../../data/services.json'
import airFreight from "../../images/air-frieght.png";
import seaFreight from "../../images/sea-frieght.png";
import clearance from "../../images/custom-clearance.png";
import haulage from "../../images/haulage.png";
import warehousing from "../../images/warehousing.png";
import movers from "../../images/MOVERS.png";
import procurement from "../../images/procurement.png"

const Home = () => {
  const services = [
    {
      title: "Air Freight",
      details:
        "We assure you of professional service as we pick your shipment from any part of the world to your destination (door-to-door service, local clearing, port-to port, door-to-port, front to end logistics). Our strong network of strategic hubs in all regions of the world enables us to offer multimode options to and from various parts of the world offering dedicated container freight stations at each hub to expedite container handling for fast onward connections.",
      // "image": "../../images/air-frieght.png"
      image: airFreight,
    },
    {
      title: "Sea Freight",
      details:
        "Sail to successful shores with us. Ocean transport is one of the key factors for strong global supply chain management. With 4Runner Global services be rest assured that you will be working with one of the leading Ocean Freight company. We offer efficient sea freight solutions customized to your needs. From Full Container Loads (FCL) to Less Container Loads (LCL/Groupage) on all major global routes with guaranteed transit times.",
      // image: "../../images/sea-frieght.png",
      image: seaFreight,
    },
    {
      title: "Custom Clearing",
      details:
        "With the network and relationship we have built over time with appropriate government agencies and personnel we guarantee you service delivery in record time for optimal use of your consignment while also bearing in mind industry friendly pricing. Our Customs Brokers desk help ease Import and Export regulations and paperwork for all of your shipments by handling all the trade compliance and procedures.",
      // image: "../../images/custom-clearance.png",
      image: clearance,
    },
    {
      title: "Haulage Services",
      details:
        "We provide transport delivery and distribution services across the states and land borders with all types of tonnage trucks with flatbed, lowbed, self-loaders, millipedes and centipedes back as well as covered back truck and buses.",
      // image: "../../images/haulage.png"
      image: haulage,
    },
    {
      title: "Warehousing",
      details:
        "We help optimize your stocks with you and create new opportunities by providing short time and longtime warehousing services with effective inventory management on third party basis in any choice location of our client.",
      // image: "../../images/warehousing.png",
      image: warehousing
    },
    {
      title: "Mover and Relocation Service",
      details:
        "Are you having headache whilst trying to change location or relocate to an entirely different region, considering that a lot of work and hassle will be put into organizing and moving your personal belongings? 4Runner is here to the rescue. We don't just package and move but we provide security coverage for your belongings.",
      // image: "../../images/MOVERS.png",
      image: movers
    },
    {
      title: "Procurement",
      details:
        "We strategically source, negotiate and making selection decision of goods and services that are of utmost satisfaction of our clients.",
      // image: "../../images/procurement.png",
      image: procurement
    },
  ];
  return (
    <main>
      <Hero />
      <Services services={services} />
      <About />
      <Gallery />
      <Clients />
      <Footer />
    </main>
  );
};

export default Home;
