import React from "react";
import "./style.css";
import About from "../../components/About/About";
import Footer from "../../components/Footer/Footer";
import { useState } from "react";
import { createPost } from '../../api/index'

const Contact = () => {
  const [postData, setPostData] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });
  const [msg, setMsg] = useState('')

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await createPost(postData).then(res => {
        if (res.status === 200) {
          setPostData({ name: "", email: "", subject: "", message: "" });
          setMsg(res.data.success.message);
          setTimeout(() => {
            setMsg('');
          }, 4000);
        }
        else{
          setMsg('Quote not successful. Try agan!')
          setTimeout(() => {
            setMsg('');
          }, 4000);
        }
      })

    } catch (error) {
      setMsg(error.message);
      setTimeout(() => {
        setMsg('');
      }, 4000);
    }
  };

  return (
    <main>
      <About />
      <div className="contact-us">
        <div className="contact container">
          <div className="contact-left">
            <h3>Contact us</h3>
            <div className="address address-ng">
              <h4>Nigeria</h4>
              <p>10 Adegbola Street by Ikeja Local Govt Area Lagos</p>
              <p>info@4runnerglobal.org</p>
              <div className="phone">
                <p>+234 909 009 6507</p>
                <p>+234 909 009 6505</p>
              </div>
            </div>
            <div className="address address-usa">
              <h4>USA</h4>
              <p>3201 E. Pioneer Pkwy #7 Arlington Texas 76010</p>
              <p>info@4runnerglobal.org</p>
              <p>+14696846899</p>
            </div>
          </div>
          <div className="contact-right">
            <h3>Send us message</h3>
            <div className={msg ==='' ? 'msg' : 'msg show'}>{msg}</div>
            <form onSubmit={handleSubmit}>
              <input
                type="text"
                value={postData.name}
                onChange={(e) =>
                  setPostData({ ...postData, name: e.target.value })
                }
                placeholder="Name"
                required
              />
              <input
                type="email"
                value={postData.email}
                onChange={(e) =>
                  setPostData({ ...postData, email: e.target.value })
                }
                placeholder="Email"
                required
              />
              <input
                type="text"
                value={postData.subject}
                onChange={(e) =>
                  setPostData({ ...postData, subject: e.target.value })
                }
                placeholder="Subject"
                required
              />
              <textarea
                cols={30}
                rows={10}
                value={postData.message}
                onChange={(e) =>
                  setPostData({ ...postData, message: e.target.value })
                }
                placeholder="Message..."
                required
              ></textarea>
              <input type="submit" value={"submit"} />
            </form>
          </div>
        </div>
      </div>
      <Footer />
    </main>
  );
};

export default Contact;
